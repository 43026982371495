//default values for any bool select component
export const BOOL_VALUES = [{ key: true, value: "Yes" }, { key: false, value: "No" }];

export const WEEKEND_PLACEMENT_VALUES = [
    { key: 0, value: "None" },
    { key: 1, value: "All" },
    { key: 2, value: "Saturdays" },
    { key: 3, value: "Sundays" },
    { key: 4, value: "None_Deprecated*" },
];

//default driver filter for users API endpoint
export const DRIVER_FILTER = { key: "roles", value: "Driver" };
export const SUPER_HERO_FILTER = { key: "roles", value: "Super Hero" };

export const PARTNER_FILTER = { key: "isPartner", value: true };

//driver list filter for drivers that are approved, in a vehicle and trained
export const DRIVER_LIST_FILTER = { key: "isDriverList", value: true };

//isActive select component label and field name
export const IS_ACTIVE_LABEL = "Active";
export const IS_ACTIVE_FIELD_NAME = "isActive";

//is select component label and field name
export const IS_HIGH_RISK_LABEL = "AreaSurcharge";
export const IS_HIGH_RISK_FIELD_NAME = "isHighRisk";

//isReturnToSenderRate select component label and field name
export const IS_RETURN_TO_SENDER_RATE_LABEL = "RTS Rate";
export const IS_RETURN_TO_SENDER_RATE_FIELD_NAME = "isReturnToSenderRate";


//approved select component label and field name
export const IS_APPROVED_LABEL = "Approved";
export const IS_APPROVED_FIELD_NAME = "isApproved";

//default select component label and field name
export const IS_DEFAULT_LABEL = "Default";
export const IS_DEFAULT_FIELD_NAME = "isDefault";

//partner select component label and field name
export const IS_PARTNER_LABEL = "Partner";
export const IS_PARTNER_FIELD_NAME = "isPartner";

//partner select component label and field name
export const POSITIVE_BALANCE_LABEL = "Positive Balance";
export const POSITIVE_BALANCE_FIELD_NAME = "positiveBalance";

//roles
export const ROLES_LABEL = "Roles";
export const ROLES_FIELD_NAME = "roles";

//account types
export const ACCOUNT_TYPE_LABEL = "Account Type";
export const ACCOUNT_TYPE_FIELD_NAME = "accountType";

//account types
export const BANK_ACCOUNT_TYPE_LABEL = "Bank Account Type";
export const BANK_ACCOUNT_TYPE_FIELD_NAME = "bankAccountType";

//funds received
export const FUNDS_RECEIVED_TYPE_LABEL = "Received Type";
export const FUNDS_RECEIVED_TYPE_FIELD_NAME = "fundsReceivedType";

export const FUNDS_RECEIVED_STATUS_LABEL = "Received Statuses";
export const FUNDS_RECEIVED_STATUS_FIELD_NAME = "fundsReceivedStatus";

//company types
export const COMPANY_TYPE_LABEL = "Company Type";
export const COMPANY_TYPE_FIELD_NAME = "companyType";

// application statuses
export const APPLICATION_STATUSES_LABEL = "Application Status"
export const APPLICATION_STATUSES_FIELD_NAME = "applicationStatus";

// application document statuses
export const DOCUMENT_STATUSES_LABEL = "Document Status"
export const DOCUMENT_STATUSES_FIELD_NAME = "documentStatus";

//delivery status
export const DELIVERY_STATUSES_LABEL = "Logistic Status";
export const DELIVERY_STATUSES_FIELD_NAME = 'logisticstatuses';

//payment status
export const PAYMENT_STATUSES_LABEL = "Payment Status";
export const PAYMENT_STATUSES_FIELD_NAME = 'paymentStatuses';

//operation status
export const OPERATION_STATUSES_LABEL = "Operation Status";
export const OPERATION_STATUSES_FIELD_NAME = 'operationStatuses';

//auto assing status
export const AUTO_ASSIGN_STATUSES_LABEL = "Auto Assign Status";
export const AUTO_ASSIGN_STATUSES_FIELD_NAME = 'autoAssignStatuses';

//job status
export const JOB_STATUS_LABEL = "Job Status";
export const JOB_STATUS_FIELD_NAME = 'jobStatuses';

//task status
export const TASK_STATUSES_LABEL = "Task Status";
export const TASK_STATUSES_FIELD_NAME = 'taskStatuses';

export const TASK_TYPES_LABEL = "Task Types";
export const TASK_TYPES_FIELD_NAME = 'taskTypes';

//vehicle types
export const VEHICLE_TYPE_LABEL = "Vehicle Type";
export const VEHICLE_TYPE_FIELD_NAME = "vehicleTypes";

//vat registered
export const VAT_REGISTERED_LABEL = "VAT Registered";
export const VAT_REGISTERED_FIELD_NAME = "isvatregistered";

//company list
export const COMPANY_LABEL = "Company";
export const COMPANY_FIELD_NAME = "companyid";

//store list
export const STORE_LABEL = "Store";
export const STORE_FIELD_NAME = "storeid";

//company list
export const PARTNER_COMPANY_LABEL = "Partner Company";
export const PARTNER_COMPANY_FIELD_NAME = "partnercompanyid";

//driver list
export const DRIVER_LABEL = "Driver";
export const DRIVER_FIELD_NAME = "driverid";

//user list
export const USER_LABEL = "User";
export const USER_FIELD_NAME = "appuserid";

//territory list
export const TERRITORY_LABEL = "Territory";
export const TERRITORY_FIELD_NAME = "territoryid";

//user territory list
export const USER_TERRITORY_LABEL = "Territory";
export const USER_TERRITORY_FIELD_NAME = "userterritoryid";

//hub types
export const HUB_TYPE_LABEL = "Hub Type";
export const HUB_TYPE_FIELD_NAME = "hubType";

//rate types
export const RATE_TYPE_LABEL = "Rate Type";
export const RATE_TYPE_FIELD_NAME = "rateType";

//sla types
export const SLA_TYPE_FIELD_NAME = "slaType";
export const SLA_TYPE_LABEL = "SLA Type";
export const SLA_TYPES_FIELD_NAME = "slaTypes";

//day of week types
export const DAY_OF_WEEK_TYPE_FIELD_NAME = "dayOfWeekType";
export const DAY_OF_WEEK_TYPE_LABEL = "Day of Week";

//parcel types
export const PARCEL_TYPE_FIELD_NAME = "parcelType";
export const PARCEL_TYPE_LABEL = "Parcel Type";

//handling types
export const HANDLING_TYPE_FIELD_NAME = "handlingType";
export const HANDLING_TYPE_LABEL = "Handling Type";

//parcel types
export const PRICING_TIER_FIELD_NAME = "pricingTier";
export const PRICING_TIER_LABEL = "Pricing Tier";

//transaction types
export const TRANSACTION_TYPE_LABEL = "Transaction Type";
export const TRANSACTION_TYPE_FIELD_NAME = "transactionType";

//transaction statuses
export const TRANSACTION_STATUS_LABEL = "Transaction Status";
export const TRANSACTION_STATUS_FIELD_NAME = "transactionStatus";

// training statues
export const TRAINING_STATUS_LABEL = "Training status";
export const TRAINING_STATUS_FIELD_NAME = "trainingStatusId";

// excludable field names
export const COMPANY_ID_EXCLUDE_FIELD_NAME = "companyIdExclude";
export const PARTNER_COMPANY_ID_EXCLUDE_FIELD_NAME = "partnerCompanyIdExclude";
export const STORE_ID_EXCLUDE_FIELD_NAME = "storeIdExclude";
export const TERRITORY_ID_EXCLUDE_FIELD_NAME = "territoryIdExclude";
export const DRIVER_ID_EXCLUDE_FIELD_NAME = "driverIdExclude";
export const USER_ID_EXCLUDE_FIELD_NAME = "userIdExclude";


//delivery date types for dashboard
export const DELIVERY_DATE_TYPE_FIELD_NAME = "datetype";
export const DELIVERY_DATE_TYPE_LABEL = "Filter date by";
//default values for delivery date types 
export const DELIVERY_DATE_TYPE_VALUES = [{ key: 1, value: "Deliver by" }, { key: 2, value: "Created" }];
export const DELIVERY_DATE_TYPE_VALUES_X = [{ key: 1, value: "Terminal Date" }, { key: 3, value: "Delivery Transaction Date" }, { key: 2, value: "Created" }];
export const DELIVERY_DATE_TYPE_VALUES_CDR = [{ key: 1, value: "Terminal Date" }, { key: 3, value: "Delivery Transaction Date" }, { key: 2, value: "Created" }, {key : 4, value: "Due Date"}];
export const TRANSACTION_DATE_TYPE_VALUES = [{ key: 1, value: "Delivery Transaction Date" }, { key: 2, value: "Terminal Date" }];
export const ALL_DATE_ENUM_VALUES = [{ key: 0, value: "All" }, { key: 1, value: "Delivery Transaction Date" }, { key: 2, value: "Terminal Date" }, { key: 3, value: "Created" }];

//driver analysis default values for date types
export const DRIVER_ANALYSIS_DATE_TYPE_VALUES = [{ key: 1, value: "Assigned Date" }, { key: 2, value: "Due Date" }];
//task statuses for task status select on job cards
export const DASHBOARD_TASK_STATUS_FIELD_NAME = "taskstatus";
export const DASHBOARD_TASK_STATUS_LABEL = "Task status";


export const UPLOAD_STATUS_FIELD_NAME = "uploadstatus";
export const UPLOAD_STATUS_LABEL = "Upload status";

//default values for task statuses
export const DASHBOARD_TASK_STATUS_VALUES = [
    { key: "Cancelled", value: "Cancelled" },
    { key: "Exception", value: "Exception" },
    { key: "Not started", value: "Not started" },
    { key: "In progress", value: "In progress" },
    { key: "Completed", value: "Completed" },
];

//select types
export const SIMPLE_SELECT = "SIMPLE_SELECT";
export const MULTIPLE_SELECT = "MULTIPLE_SELECT";
export const SELECT_TO = "SELECT_TO";
export const MINIMUM_SEARCH_CHARACTERS = 3;
export const DATE_RANGE_PICKER = "DATE_RANGE_PICKER";
export const DRIVER_SELECT = "DRIVER_SELECT";

//delivery status field name filters for dashboard
export const DELIVERY_STATUS_GROUP_FIELD_NAME = "deliveryStatus";
export const DELIVERY_STATUS_GROUP_NEEDS_ATTENTION = "Needs attention"; //NB! change this to always match server

export const INCOMPLETE_AGGREGATION_STATUS_VALUE = "In progress"; // NB! change this to always match server

//job status field name filters for dashboard
export const JOB_STATUS_GROUP_FIELD_NAME = "jobStatusGroup";
export const JOB_STATUS_GROUP_UNASSIGNED = "Unassigned"; //NB! change this to always match server

//driver status field name filters for dashboard
export const DRIVER_STATUS_GROUP_FIELD_NAME = "driverStatus";
export const DRIVER_STATUS_GROUP_NEEDS_ATTENTION = "Available";

//date picker date format
export const DATE_PICKER_FORMAT = "DD/MM/YYYY";
//from date field name
export const START_DATE_FIELD_NAME = "startDate";
//to date field name
export const END_DATE_FIELD_NAME = "endDate";

//polygon filter field name
export const POLYGON_FILTER_FIELD_NAME = "polygonfilters";

//map legend filter field names
export const MARKER_LEGEND_FILTER_FIELD_NAME = "markerlegendfilter";
export const VEHICLE_LEGEND_FILTER_FIELD_NAME = "vehiclelegendfilter";

//id filter field names
export const DELIVERY_ID_FILTER = "deliveryids";
export const JOB_ID_FILTER = "jobids";
export const USER_ID_FILTER = "userids";

//price filters
export const MIN_PRICE_FILTER_FIELD_NAME = "minprice";
export const MAX_PRICE_FILTER_FIELD_NAME = "maxprice";

//distance filters
export const MIN_DISTANCE_FILTER_FIELD_NAME = "mindistance";
export const MAX_DISTANCE_FILTER_FIELD_NAME = "maxdistance";

//parcel filters
export const MIN_SMALL_PARCEL_FILTER_FIELD_NAME = "minsmallparcels";
export const MIN_MEDIUM_PARCEL_FILTER_FIELD_NAME = "minmediumparcels";
export const MIN_LARGE_PARCEL_FILTER_FIELD_NAME = "minlargeparcels";

export const DRIVER_JOB_STATUS_FILTER_FIELD_NAME = "driverjobstatus";
export const MOBILE_APP_FIELD_NAME = "mobileapp";

//tracking filters
export const SEARCH_TERM_FIELD_NAME = "searchterm";
export const CONTACT_COUNTRY_FIELD_NAME = "contactcountrycode";
export const PHONE_NUMBER_FIELD_NAME = "phonenumber";

// special driver filters 

export const SPECIAL_DRIVER_LABEL = "Special Driver";
export const SEPCIAL_DRIVER_FIELD_NAME = "specialDriver";

/* full select dropdown templates */

//select active template
export const SELECT_IS_ACTIVE = {
    fieldName: IS_ACTIVE_FIELD_NAME,
    label: IS_ACTIVE_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_HIGH_RISK = {
    fieldName: IS_HIGH_RISK_FIELD_NAME,
    label: IS_HIGH_RISK_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select is return to sender rate template
export const SELECT_IS_RETURN_TO_SENDER_RATE = {
    fieldName: IS_RETURN_TO_SENDER_RATE_FIELD_NAME,
    label: IS_RETURN_TO_SENDER_RATE_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select approved template
export const SELECT_IS_APPROVED = {
    fieldName: IS_APPROVED_FIELD_NAME,
    label: IS_APPROVED_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select default template
export const SELECT_IS_DEFAULT = {
    fieldName: IS_DEFAULT_FIELD_NAME,
    label: IS_DEFAULT_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select partner template
export const SELECT_IS_PARTNER = {
    fieldName: IS_PARTNER_FIELD_NAME,
    label: IS_PARTNER_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_PICK_UP_ARCHIEVED = {
    fieldName: "pickUpArchived",
    label: "Pick Up Achieved",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_AD_HOC = {
    fieldName: "isAdHoc",
    label: "Ad-hoc",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_RETURN_TO_SENDER = {
    fieldName: "isReturnToSender",
    label: "Return to sender",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_ROUND_TRIP = {
    fieldName: "isRoundTrip",
    label: "Collect on delivery",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_COLLECTION = {
    fieldName: "isCollection",
    label: "Collection",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_DROP_OFF_ARCHIEVED = {
    fieldName: "dropOffArchived",
    label: "Drop Off Achieved",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_GROUP_BY_STORE = {
    fieldName: "groupByStore",
    label: "Group By Store",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

export const SELECT_IS_CASH_COLLECTION = {
    fieldName: "isCashCollection",
    label: "Cash Collection",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select partner template
export const SELECT_POSITIVE_BALANCE = {
    fieldName: POSITIVE_BALANCE_FIELD_NAME,
    label: POSITIVE_BALANCE_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select vat registered template
export const SELECT_VAT_REGISTERED = {
    fieldName: VAT_REGISTERED_FIELD_NAME,
    label: VAT_REGISTERED_LABEL,
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
};

//select roles template
export const SELECT_ROLES = {
    fieldName: ROLES_FIELD_NAME,
    label: ROLES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select roles template
export const SELECT_DRIVER_ROLES = {
    fieldName: ROLES_FIELD_NAME,
    label: "Driver roles",
    values: [
        { key: "QSR", value: "QSR" },
        //{ key: "Partner", value: "Partner" },
        { key: "Super Hero", value: "Super Hero" }
    ],
    type: MULTIPLE_SELECT
}

export const SELECT_WEEK_DAY = {
    fieldName: "scheduledDay",
    label: "Scheduled day",
    values: [
        { key: 'Monday', value: 'Monday' },
        { key: 'Tuesday', value: 'Tuesday' },
        { key: 'Wednesday', value: 'Wednesday' },
        { key: 'Thursday', value: 'Thursday' },
        { key: 'Friday', value: 'Friday' },
        { key: 'Saturday', value: 'Saturday' },
        { key: 'Sunday', value: 'Sunday' }
    ],
    type: SIMPLE_SELECT
}


//select account type template
export const SELECT_ACCOUNT_TYPES = {
    fieldName: ACCOUNT_TYPE_FIELD_NAME,
    label: ACCOUNT_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select training statues template
export const SELECT_TRAINING_STATUSES = {
    fieldName: TRAINING_STATUS_FIELD_NAME,
    label: TRAINING_STATUS_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select bank account type template
export const SELECT_BANK_ACCOUNT_TYPES = {
    fieldName: BANK_ACCOUNT_TYPE_FIELD_NAME,
    label: BANK_ACCOUNT_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select funds received types template
export const SELECT_FUNDS_RECEIVED_TYPES = {
    fieldName: FUNDS_RECEIVED_TYPE_FIELD_NAME,
    label: FUNDS_RECEIVED_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select funds received statuses template
export const SELECT_FUNDS_RECEIVED_STATUSES = {
    fieldName: FUNDS_RECEIVED_STATUS_FIELD_NAME,
    label: FUNDS_RECEIVED_STATUS_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select company types template
export const SELECT_COMPANY_TYPES = {
    fieldName: COMPANY_TYPE_FIELD_NAME,
    label: COMPANY_TYPE_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select application statuses template
export const SELECT_APPLICATION_STATUSES = {
    fieldName: APPLICATION_STATUSES_FIELD_NAME,
    label: APPLICATION_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select application document statuses template
export const SELECT_DOCUMENT_STATUSES = {
    fieldName: DOCUMENT_STATUSES_FIELD_NAME,
    label: DOCUMENT_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select company template
export const SELECT_HUBS = {
    fieldName: "hubId",
    label: "Hub",
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "hubs" //NB this needs to be the entity to search on in the API
}


//select company template
export const SELECT_COMPANIES = {
    fieldName: COMPANY_FIELD_NAME,
    label: COMPANY_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "companies" //NB this needs to be the entity to search on in the API
}

export const SELECT_PARTNER_COMPANIES = {
    fieldName: PARTNER_COMPANY_FIELD_NAME,
    label: PARTNER_COMPANY_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "companies" //NB this needs to be the entity to search on in the API
}

//select stores template
export const SELECT_STORES = {
    fieldName: STORE_FIELD_NAME,
    label: STORE_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "stores" //NB this needs to be the entity to search on in the API
}

//select vehicle types template
export const SELECT_VEHICLE_TYPES = {
    fieldName: VEHICLE_TYPE_FIELD_NAME,
    label: VEHICLE_TYPE_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select driver template
export const SELECT_DRIVER = {
    fieldName: DRIVER_FIELD_NAME,
    label: DRIVER_LABEL,
    values: [],
    type: DRIVER_SELECT,
    function: null,
    multiSelect: false,
    filter: DRIVER_LIST_FILTER,
}

//select user template
export const SELECT_USER = {
    fieldName: USER_FIELD_NAME,
    label: USER_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "users",//NB this needs to be the entity to search on in the API
    filter: {}
}

//select hub types template
export const SELECT_HUB_TYPES = {
    fieldName: HUB_TYPE_FIELD_NAME,
    label: HUB_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select territory template
export const SELECT_TERRITORIES = {
    fieldName: TERRITORY_FIELD_NAME,
    label: TERRITORY_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "territories" //NB this needs to be the entity to search on in the API
}

export const SELECT_USER_TERRITORIES = {
    fieldName: USER_TERRITORY_FIELD_NAME,
    label: USER_TERRITORY_LABEL,
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "territories" //NB this needs to be the entity to search on in the API
}

//select delivery status template
export const SELECT_AUTO_ASSIGN_STATUSES = {
    fieldName: AUTO_ASSIGN_STATUSES_FIELD_NAME,
    label: AUTO_ASSIGN_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}


//select delivery status template
export const SELECT_IS_URGENT = {
    fieldName: "isUrgent",
    label: "Is urgent",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
}

//select high risk template
export const SELECT_IS_HIGHRISK = {
    fieldName: "isHighRisk",
    label: "Area Surcharge",
    values: BOOL_VALUES,
    type: SIMPLE_SELECT
}

//select rate type template
export const SELECT_RATE_TYPES = {
    fieldName: RATE_TYPE_FIELD_NAME,
    label: RATE_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select sla type template
export const SELECT_SLA_TYPES = {
    fieldName: SLA_TYPE_FIELD_NAME,
    label: SLA_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

export const MULTISELECT_SELECT_SLA_TYPES = {
    fieldName: SLA_TYPES_FIELD_NAME,
    label: SLA_TYPE_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select delivery status template
export const SELECT_DELIVERY_STATUSES = {
    fieldName: DELIVERY_STATUSES_FIELD_NAME,
    label: DELIVERY_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select job status template
export const SELECT_JOB_STATUSES = {
    fieldName: JOB_STATUS_FIELD_NAME,
    label: JOB_STATUS_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select payment status template
export const SELECT_PAYMENT_STATUSES = {
    fieldName: PAYMENT_STATUSES_FIELD_NAME,
    label: PAYMENT_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select operation status template
export const SELECT_OPERATION_STATUSES = {
    fieldName: OPERATION_STATUSES_FIELD_NAME,
    label: OPERATION_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}


//select task status template
export const SELECT_TASK_STATUSES = {
    fieldName: TASK_STATUSES_FIELD_NAME,
    label: TASK_STATUSES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select task type template
export const SELECT_TASK_TYPES = {
    fieldName: TASK_TYPES_FIELD_NAME,
    label: TASK_TYPES_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//day of week type template
export const SELECT_DAY_OF_WEEK_TYPES = {
    fieldName: DAY_OF_WEEK_TYPE_FIELD_NAME,
    label: DAY_OF_WEEK_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//day of week type template
export const SELECT_PARCEL_TYPES = {
    fieldName: PARCEL_TYPE_FIELD_NAME,
    label: PARCEL_TYPE_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

export const SELECT_HANDLING_TYPES = {
    fieldName : HANDLING_TYPE_FIELD_NAME,
    label : HANDLING_TYPE_LABEL,
    values: [],
    type : SIMPLE_SELECT
}

//pricing tiers template
export const SELECT_PRICING_TIERS = {
    fieldName: PRICING_TIER_FIELD_NAME,
    label: PRICING_TIER_LABEL,
    values: [],
    type: MULTIPLE_SELECT
}

//select transaction type template
export const SELECT_TRANSACTION_TYPES = {
    fieldName: TRANSACTION_TYPE_FIELD_NAME,
    label: TRANSACTION_TYPE_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select transaction type template
export const SELECT_TRANSACTION_STATUSES = {
    fieldName: TRANSACTION_STATUS_FIELD_NAME,
    label: TRANSACTION_STATUS_LABEL,
    values: [],
    type: SIMPLE_SELECT
}


//select transaction type template
export const SELECT_DATE_RANGE = {
    label: null,
    fromFieldName: START_DATE_FIELD_NAME,
    fromLabel: "Start Date",
    toFieldName: END_DATE_FIELD_NAME,
    toLabel: "End Date",
    dateTypes: [],
    type: DATE_RANGE_PICKER
}

//select transaction type template
export const SELECT_UPLOAD_STATUSES = {
    fieldName: UPLOAD_STATUS_FIELD_NAME,
    label: UPLOAD_STATUS_LABEL,
    values: [],
    type: SIMPLE_SELECT
}

//select upload batch
export const SELECT_UPLOAD_BATCH = {
    fieldName: "uploadBatchId",
    label: "Batch Id",
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "uploadBatches" //NB this needs to be the entity to search on in the API
}

//select deliveries
export const SELECT_DELIVERY = {
    fieldName: "deliveryId",
    label: "Delivery Id",
    values: [],
    type: SELECT_TO,
    function: null,
    multiSelect: false,
    apiEntity: "deliveries" //NB this needs to be the entity to search on in the API
}

//toDo: Refactor this and get values via api call.
export const SELECT_DELIVERY_SOURCE_TYPES = {
    fieldName: "deliverySourceType",
    label: "Delivery source",
    values: [
        { key: "Quote", value: "Quote" },
        { key: "File upload", value: "File upload" },
        { key: "Public API", value: "Public API" },
        { key: "Delivery reattempt", value: "Delivery reattempt" }
    ],
    type: SIMPLE_SELECT
}

export const SELECT_WEBHOOK_STATUSES = {
    fieldName: "webhookStatus",
    label: "Webhook status",
    values: [],
    type: SIMPLE_SELECT
}

export const SELECT_WEBHOOK_TYPES = {
    fieldName: "woebhookType",
    label: "Webhook type",
    values: [],
    type: SIMPLE_SELECT
}

export const SELECT_AUTH_TYPES = {
    fieldName: "authType",
    label: "Auth type",
    values: [],
    type: SIMPLE_SELECT
}

//Star ratings
export const SELECT_STARS = {
    fieldName: "rates",
    label: "Ratings",
    values: [
        { key: 1, value: "1 ⭐ - Useless" },
        { key: 2, value: "2 ⭐ - Poor" },
        { key: 3, value: "3 ⭐ - Ok" },
        { key: 4, value: "4 ⭐ - Good" },
        { key: 5, value: "5 ⭐ - Excellent" }
    ],
    type: MULTIPLE_SELECT
}

export const SELECT_SCHEDULED_REPORT_TYPES = {
    fieldName: "scheduledReportTypeId",
    label: "Scheduled Report Type",
    values: [],
    type: SIMPLE_SELECT
}

export const SELECT_SCHEDULED_REPORT_PERIODS = {
    fieldName: "scheduledReportPeriodId",
    label: "Scheduled Report Period",
    values: [],
    type: SIMPLE_SELECT
}

export const SELECT_HUB_MOVEMENT_STATUSES = [
    { key: 1, value: "Moving to hub" },
    { key: 2, value: "Arrived at hub" },
    { key: 3, value: "Returned to pick-up" }
];

export const SPECIAL_DRIVER_FLAG_OPTIONS = [
    { key: `${0}`, value: "None" },
    { key: `${1}`, value: "DC Driver" },
    { key: `${2}`, value: "UCook" },
    { key: `${3}`, value: "SPL Driver" },
    { key: `${4}`, value: "Front & Back Driver" },
    { key: `${5}`, value: "Mellovan Driver" },
    { key: `${6}`, value: "Test Driver" },
]

export const SELECT_SPECIAL_DRIVERS = {
    fieldName: SEPCIAL_DRIVER_FIELD_NAME,
    label: SPECIAL_DRIVER_LABEL,
    type: MULTIPLE_SELECT
}

export const SELECT_SUPERSET_DASHBOARD_UUID = {
    "staging": [
        { "key": "FinanceMetrics", "value": "628300f4-63ce-4bde-b4b9-6378df4431e9" },
        { "key": "DeliveriesDue", "value": "f2edb594-c57d-48ff-88eb-b45976afb897" },
        { "key": "AutoAssignment", "value": "4142cd8f-fa88-47eb-8089-33ee32f6c482" },
        { "key": "DriverSchedule", "value": "cb8b7c4f-7d6a-4d6c-94af-c0166a664e64" },
        { "key": "AddressVariationReport", "value": "0dca160f-2baa-4125-951e-bcc8ef3d3724" },
        { "key": "DriverPayAnalysis", "value": "5ada88c6-40a1-469c-8a65-d3ff34f8dd5c" },
        { "key": "DriverWaitTime", "value": "21581a59-03d4-45ca-b177-f486301aeb98" },
        { "key": "MassMartExceptions", "value": "b48bf1dc-88bd-4291-9e8a-be55ddf23b0f" },
        { "key": "AutoDriverAssignment", "value": "5f88dadb-18c1-4a29-8370-1fe2481d8d06" },
        { "key": "AmendmentReport", "value": "e7d8ab33-ba2f-4b84-85f7-0a662c72a9d6" },
        { "key": "LocationProximityReport", "value": "69333b43-d0d0-485c-a057-dde1a7d2bf43" }
    ],
    "integrations": [
        { "key": "FinanceMetrics", "value": "628300f4-63ce-4bde-b4b9-6378df4431e9" },
        { "key": "DeliveriesDue", "value": "f2edb594-c57d-48ff-88eb-b45976afb897" },
        { "key": "AutoAssignment", "value": "4142cd8f-fa88-47eb-8089-33ee32f6c482" },
        { "key": "DriverSchedule", "value": "cb8b7c4f-7d6a-4d6c-94af-c0166a664e64" },
        { "key": "AddressVariationReport", "value": "797d3bc5-607f-4f48-8663-9d7d77ca25b4" },
        { "key": "DriverPayAnalysis", "value": "0eba13eb-a3f4-4d63-9038-f4deb3a970bc" },
        { "key": "DriverWaitTime", "value": "21581a59-03d4-45ca-b177-f486301aeb98" },
        { "key": "MassMartExceptions", "value": "cf2c3804-bc41-484b-954a-808aac1f4ce8" },
        { "key": "AutoDriverAssignment", "value": "5034514e-2f15-46e7-8346-bb2762036755" },
        { "key": "AmendmentReport", "value": "0f7f8ea4-e485-4623-b112-e61f4f3e8f2a" },
        { "key": "LocationProximityReport", "value": "129428c6-83f7-4d40-8733-e01ea30fec55" }
    ],
    "production": [
        { "key": "FinanceMetrics", "value": "2b86d4aa-404d-4e08-ba63-77f2e10a0e3b" },
        { "key": "DeliveriesDue", "value": "6d295cee-882d-4333-8618-57c1ea53c1cb" },
        { "key": "AutoAssignment", "value": "0b3fe492-cfd7-4eb0-b71e-471c2327791e" },
        { "key": "DriverSchedule", "value": "46d3150c-7617-4ca8-9947-579c5d8b1bd9" },
        { "key": "AddressVariationReport", "value": "eadd3a2b-548b-4c80-822a-09b1b44cbad5" },
        { "key": "DriverPayAnalysis", "value": "feb4e576-938a-4920-932c-6ac1fb3c1daf" },
        { "key": "DriverWaitTime", "value": "f275fa31-0eb2-4d96-9800-6d0f75b01246" },
        { "key": "MassMartExceptions", "value": "9d3cba9d-e2c5-49a0-bcc3-12fc4514e793" },
        { "key": "AutoDriverAssignment", "value": "497c25e3-1f2b-4545-9ecf-da6d36620437" },
        { "key": "AmendmentReport", "value": "94492f55-987e-4f71-926a-bb791c82c257" },
        { "key": "LocationProximityReport", "value": "9b5a33b7-e77a-4837-8591-3cc5ae20340b" }
    ]
}

export const SLA_TYPES_VALUES = [{key:1,value:"Priority"},{key:2,value:"Standard"},{key:3,value:"Hyperlocal"},{key:4,value:"Emergency Relief"}]

export const LOGISTIC_STATUS = [{key:30001,value:"Delivered"},{key:20001,value:"Pending drop-off"},{key:10001,value:"Pending pick-up"},{key:2501,value:"Ready"},{key:15001,value:"Collected"},{key:0,value:"Pending quote"},{key:-10001,value:"Pick-up exception"},{key:-1,value:"Cancelled quote"},{key:-11001,value:"Drop-off exception"},{key:-3001,value:"Returned to sender"},{key:-2001,value:"Cancelled with fee"},{key:-1001,value:"Refunded"},{key:-1501,value:"Reversed full refund"},{key:1000,value:"Pending Staging"},{key:-12001,value:"Undelivered"}]
